import { useEffect, useState } from "react";
import servicesList from "../data/services.json";
import { useParams } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import Testimonials from "../components/testimonials/Testimonials";
import QouteBanner from "../components/quote-banner/QuoteBanner";
import Button from "../shared/ui/Button";
import { MdOutlineTouchApp } from "react-icons/md";

const Service = () => {
  const serviceId = useParams().id;

  const [service, setService] = useState(null);
  const [image, setImage] = useState(null);
  useEffect(() => {
    const service = servicesList.services.find(
      (service) => Number(service.id) === Number(serviceId)
    );
    setService(service);
    setImage(require(`../assets/images/${service.image}`));
  }, [serviceId]);

  return (
    <section>
      <div className="container mx-auto">
        {service && (
          <div className="flex flex-col md:flex-row gap-12 p-5">
            <div>
              <img
                src={image}
                alt={service.name}
                className="w-full max-h-[600px] rounded-lg"
              />
            </div>
            <div className="flex flex-col gap-3">
              <h1 className="text-3xl font-bold text-dark-green">
                {service.name}
              </h1>
              <p className="max-w-lg text-xl">{service.description}</p>
              <div className="flex flex-col gap-3">
                {service.features.map((feat, index) => {
                  return (
                    <p key={index} className="flex items-center gap-2 text-k">
                      <span className="text-keppel-400 w-10 h-10 rounded-full bg-keppel-100 flex items-center justify-center border border-keppel-400 border-dashed hover:bg-dark-green hover:text-white transition-all hover:border-none shrink-0">
                        <FaCheck />
                      </span>
                      <span className="font-semibold">{feat}</span>
                    </p>
                  );
                })}
              </div>
              <Button link={"/contact"} className={"mt-5"}>
                Get in Touch <MdOutlineTouchApp className="text-xl" />
              </Button>
            </div>
          </div>
        )}
        {service?.subservices && (
          <div className="grid grid-cols-2 gap-5 md:grid-cols-4 my-8 p-5">
            {service.subservices.map((subservice, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col gap-2 rounded-lg shadow-md border p-5"
                >
                  <h2 className="text-xl font-bold text-dark-green">
                    {subservice.name}
                  </h2>
                  <p className="text-sm">{subservice.description}</p>
                  <div className="flex flex-col gap-3 mt-4">
                    {subservice.features.map((feat, index) => {
                      return (
                        <p key={index} className="flex items-center gap-2">
                          <span className="text-keppel-400 flex items-center justify-center shrink-0">
                            <FaCheck />
                          </span>
                          <span className="text-sm">{feat}</span>
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <QouteBanner />
      <Testimonials />
    </section>
  );
};

export default Service;
