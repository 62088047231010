import React from "react";
import TestimonialsList from "../../data/testimonials.json";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { PiStarFill } from "react-icons/pi";

const Testimonials = () => {
  const testimonials = TestimonialsList.map((testimonial, index) => {
    return (
      <div
        key={index}
        className="p-5 rounded-md shadow-md bg-keppel-50 flex flex-col gap-5 relative hover:-translate-y-3 transition-transform"
      >
        <div className="flex items-center gap-3">
          <p className="p-4 bg-keppel-600 text-keppel-100 text-2xl rounded-full w-12 h-12 flex items-center justify-center">
            {testimonial.name[0]}
          </p>
          <div className="flex flex-col">
            <p className="flex items-center gap-1 text-lg">
              {testimonial.name} <RiVerifiedBadgeFill />
            </p>
            <div className="flex text-sm">
              <PiStarFill />
              <PiStarFill />
              <PiStarFill />
              <PiStarFill />
              <PiStarFill />
            </div>
          </div>
        </div>
        <p className="text-sm italic ">{testimonial.message}</p>
      </div>
    );
  });

  return (
    <section className="container mx-auto p-5 mt-8">
      <h3 className="text-3xl font-bold text-center">
        What our <span className="text-keppel-500">Customer</span> say about us
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 mt-8">
        {testimonials}
      </div>
    </section>
  );
};

export default Testimonials;
