import React from "react";
import { RiHomeHeartLine } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { BiLeaf } from "react-icons/bi";

const Guarantees = () => {
  return (
    <section className="container mx-auto mt-6 p-5">
      <h1 className="text-center font-bold text-keppel-500 text-3xl mb-6">
        Satisfaction Guaranteed
      </h1>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
        <div className="flex flex-col gap-3 items-center">
          <div className="p-4 rounded-full border w-fit  border-keppel-400 text-keppel-500 text-3xl">
            <RiHomeHeartLine />
          </div>
          <h3 className="font-semibold ">We Treat Your Home Like Our Own</h3>
        </div>

        <div className="flex flex-col gap-3 items-center">
          <div className="p-4 rounded-full border w-fit  border-keppel-400 text-keppel-500 text-3xl">
            <IoPricetagsOutline />
          </div>

          <h3 className="font-semibold ">Immediate, Accurate Online Quotes</h3>
        </div>

        <div className="flex flex-col gap-3 items-center">
          <div className="p-4 rounded-full border w-fit  border-keppel-400 text-keppel-500 text-3xl">
            <IoShieldCheckmarkOutline />
          </div>

          <h3 className="font-semibold ">Fully Bonded and Insured</h3>
        </div>

        <div className="flex flex-col gap-3 items-center">
          <div className="p-4 rounded-full border w-fit  border-keppel-400 text-keppel-500 text-3xl">
            <BiLeaf />
          </div>

          <h3 className="font-semibold ">Commitment to the Environment</h3>
        </div>
      </div>
    </section>
  );
};

export default Guarantees;
