import { useEffect, useState } from "react";
import servicesList from "../data/services.json";
import ServiceCard from "../components/service-card/ServiceCard";
import QuoteBanner from "../components/quote-banner/QuoteBanner";
import MainForm from "../components/forms/MainForm";
import Features from "../components/features/Features";
const Services = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    setServices(servicesList.services);
  }, []);

  return (
    <section>
      <div className="container mx-auto p-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        {services &&
          services.map((service) => {
            return <ServiceCard key={service.id} service={service} />;
          })}
      </div>

      <Features />
      <QuoteBanner />
      <div className="-mb-8">
        <MainForm />
      </div>
    </section>
  );
};

export default Services;
