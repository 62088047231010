import React from "react";
import { Link } from "react-router-dom";

const Button = ({ children, className, onClick, link, secondary }) => {
  return link ? (
    <Link to={link}>
      <button
        onClick={onClick}
        className={`flex items-center justify-center gap-2 px-2 py-1.5 w-40  hover:bg-keppel-600 hover:text-white transition-colors rounded-md font-bold ${
          secondary
            ? "text-keppel-400 bg-keppel-100 border border-keppel-400"
            : "text-white bg-keppel-400"
        } ${className}`}
      >
        {children}
      </button>
    </Link>
  ) : (
    <button
      onClick={onClick}
      className={`flex items-center justify-center gap-2 px-2 py-1.5 w-40  hover:bg-keppel-600 hover:text-white transition-colors rounded-md font-bold ${
        secondary
          ? "text-keppel-400 bg-keppel-100 border border-keppel-400"
          : "text-white bg-keppel-400"
      } ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
