import React from "react";
import { Link } from "react-router-dom";

const Copyright = () => {
  return (
    <div className="bg-keppel-50 text-gray-500 ml-4">
      <p>
        {" "}
        &copy; {new Date().getFullYear()} SSA Home Services.
        <Link
          to="/privacy-policy"
          className="ms-3 hover:text-dark-green font-semibold"
        >
          Privacy Policy.
        </Link>
        <Link
          to="/terms-of-service"
          className="ms-3 hover:text-dark-green font-semibold"
        >
          Terms of Service.
        </Link>
      </p>
    </div>
  );
};

export default Copyright;
