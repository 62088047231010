import React from "react";
import HeroImg from "../../assets/images/hero1.webp";
import { IoIosArrowForward } from "react-icons/io";
import Button from "../../shared/ui/Button";
import { PiPhoneCallFill } from "react-icons/pi";

const Hero = () => {
  return (
    <section>
      <div className="flex flex-col-reverse md:flex-row items-center justify-between p-8">
        <div className="flex flex-col gap-y-4">
          <h1 className="text-2xl md:text-5xl lg:text-6xl font-bold">
            Top-Rated House Cleaning in Nashville, Murfreesboro & Surrounding
            Areas
          </h1>
          <div>
            <p className="text-xl md:text-2xl text-keppel-500">
              Leave the Cleaning to Us – Enjoy More of What You Love!
            </p>
          </div>

          <div className="flex items-center gap-5">
            <a href="mailto:info@ssahomeservices.com">
              <Button>
                Get Free Quote <IoIosArrowForward className="font-bold" />
              </Button>
            </a>
            <div className="px-3 py-1.5  hover:bg-keppel-600 rounded-md transition-all hover:text-white w-40 border border-keppel-200 bg-keppel-100 text-keppel-600">
              <a
                href="tel:615-606-9561"
                onClick={() => {
                  window.open("tel:615-606-9561");
                }}
                className="flex items-center gap-1"
              >
                <PiPhoneCallFill className="text-xl" />
                <span>{"(615) "}606-9561</span>
              </a>
            </div>
          </div>
        </div>
        <div className="max-w-2xl">
          <img loading="lazy" src={HeroImg} alt="3 People Cleaning Service" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
