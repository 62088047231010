import React, { useState, useRef } from "react";
import States from "../../data/us_states.json";
import servicesList from "../../data/services.json";
import Button from "./Button";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Form = () => {
  const [cities, setCites] = useState(null);

  const form = useRef();

  const notify = () => toast.success("Email Sent Successfully");
  const notifyError = () => toast.error("Faild sent Email");

  const services = servicesList.services.map((service, index) => {
    return (
      <option key={index} value={service.name}>
        {service.name}
      </option>
    );
  });

  const pressureWashing = servicesList.services.find(
    (service) => service.name === "Pressure Washing"
  );
  const pressureServices = pressureWashing.subservices.map((service) => {
    console.log(service);

    return (
      <option key={service.id + 7} value={service.name}>
        {service.name}
      </option>
    );
  });

  const states = Object.keys(States).map((state, index) => {
    return (
      <option key={index} value={state}>
        {state}
      </option>
    );
  });

  const handleChange = (e) => {
    const selectedState = e.target.value;
    setCites(
      States[selectedState].map((city, index) => {
        return (
          <option key={index} value={city}>
            {city}
          </option>
        );
      })
    );
  };

  const sendEmail = () => {
    emailjs
      .sendForm("service_eqidqpc", "template_tqtjfan", form.current, {
        publicKey: "dcgJwOCqjQaBeHzaY",
      })
      .then(
        () => {
          notify();
          form.current.reset();
        },
        (error) => {
          notifyError();
        }
      );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (e) => {
    sendEmail();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={form}>
      <div className="grid grid-cols-2 gap-5 items-start">
        <div className="flex flex-col">
          <input
            {...register("first_name", { required: "First Name is Required" })}
            placeholder="First Name"
            aria-invalid={errors.first_name ? "true" : "false"}
          />
          {errors.first_name && (
            <p className="error">{errors.first_name.message}</p>
          )}
        </div>

        <div className="flex flex-col">
          <input
            {...register("last_name", { required: true })}
            placeholder="Last Name"
            aria-invalid={errors.last_name ? "true" : "false"}
          />
          {errors.last_name && <p className="error">Last Name is Required</p>}
        </div>

        <div className="flex flex-col">
          <input
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
            placeholder="Email Address"
            aria-invalid={errors.email ? "true" : "false"}
          />
          {errors.email && (
            <p className="error">Please Enter a vaild Email Address</p>
          )}
        </div>

        <div className="flex flex-col">
          <input
            {...register("phone", { required: true })}
            placeholder="Phone Number"
            aria-invalid={errors.phone ? "true" : "false"}
          />
          {errors.phone && <p className="error">Phone Number is Required</p>}
        </div>

        <div className="flex flex-col col-span-2">
          <input
            {...register("address", { required: true })}
            placeholder="Street Address"
            aria-invalid={errors.address ? "true" : "false"}
          />
          {errors.address && <p className="error">Address is Required</p>}
        </div>

        <div className="flex">
          <select
            {...register("state", { required: true })}
            className="w-full"
            onChange={(e) => handleChange(e)}
            aria-invalid={errors.state ? "true" : "false"}
            placeholder="State"
          >
            <option disabled>State</option>
            {states}
          </select>
          {errors.state && <p className="error">State is Required</p>}
        </div>

        <div className="flex flex-col">
          <select
            {...register("city", { required: true })}
            className="w-full"
            aria-invalid={errors.city ? "true" : "false"}
            placeholder="City"
          >
            <option disabled>City</option>
            {cities}
          </select>
          {errors.city && <p className="error">City is Required</p>}
        </div>

        <div className="flex flex-col">
          <input
            {...register("zip_code", { required: true })}
            placeholder="Zip/ Postal Code"
            aria-invalid={errors.zip_code ? "true" : "false"}
          />
          {errors.zip_code && (
            <p className="error">Zip/ Postal Code is Required</p>
          )}
        </div>

        <div className="flex">
          <select
            {...register("desired_service", { required: true })}
            className="w-full"
            aria-invalid={errors.desired_service ? "true" : "false"}
            placeholder="Desired Service"
          >
            <option disabled>Desired Service</option>
            {services}
            {pressureServices}
          </select>
          {errors.desired_service && (
            <p className="error">Desired Service is Required</p>
          )}
        </div>

        <div className="flex col-span-2">
          <textarea
            {...register("notes")}
            placeholder="Notes"
            className="w-full resize-none"
          ></textarea>
        </div>

        <div className="col-span-2">
          <Button type="submit" className={"w-full"}>
            Submit
          </Button>
          <ToastContainer />
        </div>
      </div>
    </form>
  );
};

export default Form;
