import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../shared/ui/Button";

import Logo from "../../assets/images/ssa-logo.svg";
import { RiMenu5Fill } from "react-icons/ri";
import { HiMiniXMark } from "react-icons/hi2";
import { IoIosArrowForward } from "react-icons/io";
import { PiPhoneCallFill } from "react-icons/pi";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(false);
  };

  return (
    <header className="container mx-auto overscroll-none z-50">
      <div className="flex items-center justify-between">
        <div className="w-48 md:min-w-36">
          <Link to={"/"}>
            <img src={Logo} alt="SSA Home Services" />
          </Link>
        </div>

        <div className=" md:hidden" onClick={() => setIsOpen(true)}>
          <RiMenu5Fill className="text-3xl hover:text-dark-green me-4" />
        </div>

        <nav className="hidden md:block">
          <ul className="flex items-center justify-between gap-x-5">
            <li className="nav-link">
              <Link to="/">Home</Link>
            </li>
            <li className="nav-link">
              <Link to="/about">About</Link>
            </li>
            <li className="nav-link">
              <Link to="/services">Services</Link>
            </li>
            <li className="nav-link">
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <a href="mailto:info@ssahomeservices.com">
                <Button>
                  Get Free Quote <IoIosArrowForward className="font-bold" />
                </Button>
              </a>
            </li>
            <li
              onClick={handleClick}
              className="px-3 py-1.5  hover:bg-keppel-600 rounded-md transition-all hover:text-white w-40 border border-keppel-200  bg-keppel-100 text-keppel-600"
            >
              <a
                href="tel:615-606-9561"
                onClick={() => {
                  window.open("tel:615-606-9561");
                }}
                className="flex items-center gap-1"
              >
                <PiPhoneCallFill className="text-xl" />
                <span>{"(615) "}606-9561</span>
              </a>
            </li>
          </ul>
        </nav>

        <nav
          className={`flex flex-col md:hidden absolute w-full h-screen bg-white top-0  transition-all duration-500 z-50 ${
            isOpen ? "left-0 no-doc-scroll" : "-left-full"
          }`}
        >
          <div onClick={() => setIsOpen(false)}>
            <HiMiniXMark className="text-4xl ms-auto me-4 mt-4 hover:text-dark-green" />
          </div>

          <ul className="mobile-nav flex flex-col justify-between p-5 gap-y-6">
            <li className="nav-link" onClick={handleClick}>
              <Link to="/">Home</Link>
            </li>
            <li className="nav-link" onClick={handleClick}>
              <Link to="/about">About</Link>
            </li>
            <li className="nav-link" onClick={handleClick}>
              <Link to="/services">Services</Link>
            </li>
            <li className="nav-link" onClick={handleClick}>
              <Link to="/contact">Contact</Link>
            </li>
            <li onClick={handleClick}>
              <a href="mailto:info@ssahomeservices.com">
                <Button>
                  Get Free Quote <IoIosArrowForward className="font-bold" />
                </Button>
              </a>
            </li>
            <li
              onClick={handleClick}
              className="px-3 py-1.5  hover:bg-keppel-600 rounded-md transition-all hover:text-white w-40 border border-keppel-200 bg-keppel-100 text-keppel-600"
            >
              <a
                href="tel:615-606-9561"
                onClick={() => {
                  window.open("tel:615-606-9561");
                }}
                className="flex items-center gap-1"
              >
                <PiPhoneCallFill className="text-xl" />
                <span>{"(615) "}606-9561</span>
              </a>
            </li>
          </ul>
          <div className="p-5 mb-5 flex-grow flex flex-col justify-end ">
            <div>{/*Social Media Links*/}</div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
