import React from "react";
import Hero from "../components/hero/Hero";
import Features from "../components/features/Features";
import ServicesList from "../components/services-list/ServicesList";
import Guarantees from "../components/guarantees/Guarantees";
import Testimonials from "../components/testimonials/Testimonials";
import Commitment from "../components/commitment/Commitment";
import MainForm from "../components/forms/MainForm";
import QuoteBanner from "../components/quote-banner/QuoteBanner";
const Home = () => {
  return (
    <>
      <Hero />
      <ServicesList />
      <QuoteBanner />
      <Features />
      <Commitment />
      <QuoteBanner />
      <Guarantees />
      <MainForm />
      <Testimonials />
    </>
  );
};

export default Home;
