import React from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import Staff from "../../assets/images/staff.svg";
const Commitment = () => {
  return (
    <section className="container mx-auto p-5">
      <h1 className="text-center text-3xl my-8">
        A Cleaning Service That Delivers{" "}
        <strong className="text-keppel-500">SPOTLESS</strong> Results
      </h1>

      <div className="flex flex-col-reverse lg:flex-row gap-4 items-center">
        <div className="flex flex-col gap-y-6">
          <p className="">
            At{" "}
            <a href="/" className="text-keppel-500 font-semibold">
              SSA Cleaning Services
            </a>
            , we are fully dedicated to ensuring complete customer satisfaction
            with every cleaning job. From the moment you call us to the final
            inspection, our team is committed to providing your home with the
            highest level of care and respect.
          </p>

          <div className="flex flex-col gap-y-4">
            <div className="flex items-center gap-4">
              <FaRegCircleCheck className="text-3xl text-keppel-500" />

              <h2 className="font-semibold">Expertly Trained Cleaning Staff</h2>
            </div>

            <div className="flex items-center gap-4">
              <FaRegCircleCheck className="text-3xl text-keppel-500" />
              <h2 className="font-semibold">
                Fully Licensed, Insured, and Bonded
              </h2>
            </div>

            <div className="flex items-center gap-4">
              <FaRegCircleCheck className="text-3xl text-keppel-500" />
              <h2 className="font-semibold">Thorough Background Checks</h2>
            </div>

            <div className="flex items-center gap-4">
              <FaRegCircleCheck className="text-3xl text-keppel-500" />

              <h2 className="font-semibold">Satisfaction Guaranteed</h2>
            </div>
          </div>

          <p className="">
            Our cleaners are among the best in the business, dedicated to
            delivering outstanding results. You’ll appreciate having these
            skilled professionals in your home, and they’ll ensure every job is
            done right.
          </p>
        </div>

        <div>
          <img className="w-full" src={Staff} alt="Staff Cleaning" />
        </div>
      </div>
    </section>
  );
};

export default Commitment;
