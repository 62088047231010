import React from "react";
import MainForm from "../components/forms/MainForm";

const Contact = () => {
  return (
    <div>
      <h1 className="text-3xl text-dark-green text-center font-bold">
        Contact Us
      </h1>
      <div className="mt-8 -mb-8">
        <MainForm />
      </div>
    </div>
  );
};

export default Contact;
