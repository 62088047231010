import React from "react";

const TermsOfService = () => {
  return (
    <div>
      <div class="container mx-auto">
        <h1 className="text-center font-bold text-dark-green text-3xl mt-8">
          Terms of Service
        </h1>
        <p>
          Welcome to SSA Home Services. By accessing our website and using our
          services, you agree to be bound by the following terms and conditions:
        </p>

        <h2 className="border-b pb-2 my-4 text-xl font-semibold">
          Use of Our Services
        </h2>
        <p>
          SSA Home Services provides home improvement and maintenance solutions.
          You agree to use our services only for lawful purposes and in
          accordance with these terms.
        </p>

        <h2 className="border-b pb-2 my-4 text-xl font-semibold">
          Intellectual Property
        </h2>
        <p>
          All content on our website, including text, graphics, logos, images,
          and software, is the property of SSA Home Services or its content
          suppliers and is protected by copyright and other intellectual
          property laws.
        </p>

        <h2 className="border-b pb-2 my-4 text-xl font-semibold">
          Limitation of Liability
        </h2>
        <p>
          SSA Home Services will not be liable for any damages that result from
          the use of, or the inability to use, the materials on our site or the
          performance of the services, even if SSA Home Services has been
          advised of the possibility of such damages.
        </p>

        <h2 className="border-b pb-2 my-4 text-xl font-semibold">
          Changes to These Terms
        </h2>
        <p>
          We reserve the right to modify these terms at any time. You should
          check this page periodically for changes. Your continued use of our
          services after any modifications constitutes your acceptance of the
          new terms.
        </p>

        <h2 className="border-b pb-2 my-4 text-xl font-semibold">Contact Us</h2>
        <p>
          If you have any questions about these Terms of Service, please contact
          us at:
        </p>
        <p>Email: info@ssahomeservices.com</p>
        <p>Phone: (615) 606-9561</p>
      </div>
    </div>
  );
};

export default TermsOfService;
