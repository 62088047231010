import React from "react";
import Feat1 from "../../assets/images/feat1.webp";
import Feat2 from "../../assets/images/feat2.webp";
import Feat3 from "../../assets/images/feat3.webp";

const Features = () => {
  return (
    <section className="container mx-auto">
      <div className="flex flex-col items-center p-8">
        <h1 className="text-2xl font-bold mb-8 text-center">
          Cleaning Day Will Be Your{" "}
          <span className="text-keppel-400">Favorite Day!</span>
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 place-items-center w-full">
          <div className="flex flex-col items-center">
            <img
              loading="lazy"
              className="w-full max-w-72"
              src={Feat1}
              alt="Feature 1"
            />
            <p className="text-keppel-700 text-xl text-center">
              A Sparkling Clean House You Can Be Proud Of
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img
              loading="lazy"
              className="w-full max-w-72"
              src={Feat3}
              alt="Feature 3"
            />
            <p className="text-keppel-700 text-xl text-center">
              More Time Just For You. Relax, Refresh & Renew!
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img
              loading="lazy"
              className="w-full max-w-72"
              src={Feat2}
              alt="Feature 2"
            />
            <p className="text-keppel-700 text-xl text-center">
              More Free Time For Family, Friends & Fun!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
