import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <div class="container mx-auto">
        <h1 className="text-3xl mt-3 text-dark-green text-center font-bold mb-3">
          Privacy Policy
        </h1>
        <p>
          At SSA Home Services, we are committed to protecting your privacy.
          This Privacy Policy explains how we collect, use, disclose, and
          safeguard your information when you visit our website or use our
          services.
        </p>

        <h2 className="text-xl border-b pb-1 my-4 font-semibold">
          Information We Collect
        </h2>
        <p>
          We may collect personal information that you provide to us directly,
          such as your name, email address, phone number, and address when you
          fill out a form on our website or contact us.
        </p>

        <h2 className="text-xl border-b pb-1 my-4 font-semibold">
          How We Use Your Information
        </h2>
        <p className="font-semibold">We use the information we collect to:</p>
        <ul className="flex flex-col gap-2">
          <li>• Provide, operate, and maintain our services</li>
          <li>• Improve, personalize, and expand our services</li>
          <li>• Understand and analyze how you use our services</li>
          <li>
            • Communicate with you, either directly or through one of our
            partners, including for customer service and promotional purposes
          </li>
          <li>• Process your transactions and manage your orders</li>
        </ul>

        <h2 className="text-xl border-b pb-1 my-4 font-semibold">
          Sharing Your Information
        </h2>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your
          Personally Identifiable Information unless we provide users with
          advance notice. This does not include website hosting partners and
          other parties who assist us in operating our website, conducting our
          business, or serving our users, so long as those parties agree to keep
          this information confidential.
        </p>

        <h2 className="text-xl border-b pb-1 my-4 font-semibold">
          Security of Your Information
        </h2>
        <p>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable.
        </p>

        <h2 className="text-xl border-b pb-1 my-4 font-semibold">Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <p>Email: info@ssahomeservices.com</p>
        <p>Phone: (615) 606-9561</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
