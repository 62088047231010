import React from "react";
import Form from "../../shared/ui/Form";
import Quote from "../../assets/images/quote.svg";

const MainForm = () => {
  return (
    <section className="bg-keppel-50 p-5 ">
      <div className="flex items-center gap-12 container mx-auto">
        <div className="hidden md:block max-w-96">
          <img className="w-full" src={Quote} alt="Woman thinking" />
        </div>

        <div className="flex-1">
          <h2 className="text-3xl font-bold text-center mb-5">
            Get a Free Quote Now!
          </h2>
          <Form />
        </div>
      </div>
    </section>
  );
};

export default MainForm;
