import React from "react";
import Button from "../shared/ui/Button";

const NoPage = () => {
  return (
    <section className="container mx-auto ">
      <div className="flex flex-col gap-5 items-center justify-center h-screen text-center">
        <h1 className="text-8xl font-bold text-dark-green">404</h1>
        <h2 className="text-6xl font-semibold"> Page Not Found</h2>
        <Button link={"/"}>Back To Home Page</Button>
      </div>
    </section>
  );
};

export default NoPage;
