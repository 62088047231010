import servicesList from "../../data/services.json";
import ServiceCard from "../service-card/ServiceCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.css";

const ServicesList = (props) => {
  const services = servicesList.services;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1440 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    laptop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <section className="p-5 mt-6">
      <h1 className="text-center text-3xl font-bold text-keppel-500 mb-6">
        Our Services
      </h1>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={500}
        showDots
        infinite={true}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={props.deviceType}
        centerMode={false}
      >
        {services.map((service) => {
          return <ServiceCard key={service.id} service={service} />;
        })}
      </Carousel>
    </section>
  );
};

export default ServicesList;
