import React from "react";
import Button from "../../shared/ui/Button";
import { IoIosArrowForward } from "react-icons/io";
import { PiPhoneCallFill } from "react-icons/pi";

const QuoteBanner = () => {
  return (
    <section className="bg-keppel-50">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-evenly p-4 text-keppel-900 gap-y-4">
        <h1 className="text-2xl">
          You're Going To Love Having A Cleaning Service!
        </h1>

        <div className="flex flex-col gap-4 items-center">
          <div className="flex flex-col md:flex-row items-center gap-4">
            <a href="mailto:info@ssahomeservices.com">
              <Button className={"bg-keppel-100"}>
                Get Free Quote <IoIosArrowForward className="font-bold" />
              </Button>
            </a>
            <span className="font-bold">OR</span>
            <a
              href="tel:615-606-9561"
              onClick={() => {
                window.open("tel:615-606-9561");
              }}
              className="flex items-center gap-1 px-3 py-1.5  bg-keppel-400 rounded-md transition-all hover:bg-keppel-600 w-40  text-white"
            >
              <PiPhoneCallFill className="text-xl" />
              <span>{"(615) "}606-9561</span>
            </a>
          </div>
          <p className="text-xl">Call us for a free quote today!</p>
        </div>
      </div>
    </section>
  );
};

export default QuoteBanner;
