import React from "react";
import Logo from "../../assets/images/ssa-logo.svg";
import { Link } from "react-router-dom";
import serviceList from "../../data/services.json";
import { PiPhoneCallFill } from "react-icons/pi";

const Footer = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const services = serviceList.services.map((service, index) => {
    return (
      <li className="nav-link w-fit" key={index}>
        <Link onClick={handleClick} to={"/services/" + service.id}>
          {service.name}
        </Link>
      </li>
    );
  });

  return (
    <footer className="w-full bg-keppel-50 p-2 border-t bottom-2 border-keppel-500 mt-8 overflow-hidden">
      <div className="container mx-auto grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-8">
        <div className="flex flex-col items-center">
          <img className="w-full" src={Logo} alt="SSA Home Services" />
          <h2 className="font-semibold text-dark-green">
            Bringing Sparkle to Every Home, Every Time
          </h2>
          <div>{/*Social Media Links*/}</div>
        </div>

        <div>
          <h3 className="font-semibold text-dark-green text-xl  border-b-2 border-dark-green mb-2">
            Quick Links
          </h3>
          <ul className="flex flex-col gap-2">
            <li className="nav-link w-fit">
              <Link onClick={handleClick} to="/">
                Home
              </Link>
            </li>
            <li className="nav-link w-fit">
              <Link onClick={handleClick} to="/about">
                About Us
              </Link>
            </li>
            <li className="nav-link w-fit">
              <Link onClick={handleClick} to="/contact">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <h3 className="font-semibold text-dark-green text-xl  border-b-2 border-dark-green mb-2">
            Services
          </h3>
          <ul className="flex flex-col gap-2">{services}</ul>
        </div>

        <div className="flex flex-col">
          <h3 className="font-semibold text-dark-green text-xl  border-b-2 border-dark-green mb-2">
            Contact
          </h3>
          <address className="text-xs md:text-base">
            <a href="mailto:info@ssahomeservices.com">
              info@ssahomeservices.com
            </a>
            <p>(615)- 606-9561</p>
            <p>
              Mon - Fri <br /> (8:00 AM - 5:00 PM)
            </p>
          </address>

          <div className="flex flex-col gap-2 mt-2">
            <a
              href="tel:615-606-9561"
              className="flex items-center gap-1 px-3 py-1.5  bg-dark-green rounded-md transition-all hover:bg-keppel-600 w-40  text-white"
            >
              <PiPhoneCallFill className="text-xl" />
              <span>{"(615) "}606-9561</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
