import React from "react";
import Button from "../shared/ui/Button";

const About = () => {
  return (
    <section>
      <div class="container mx-auto p-5">
        <h1 className="text-center text-3xl text-dark-green font-bold">
          About Us
        </h1>

        <div className="flex flex-col gap-8">
          <div>
            <p className="text-2xl">
              <strong>Welcome to SSA Home Services!</strong>
            </p>
            <p>
              At SSA Home Services, we are dedicated to providing top-notch home
              improvement and maintenance solutions to our valued clients. With
              years of experience in the industry, we pride ourselves on
              delivering high-quality, reliable, and professional services that
              enhance the comfort, functionality, and aesthetic appeal of your
              home.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold border-b pb-1">Our Mission</h2>
            <p>
              Our mission is to offer exceptional home services that exceed our
              customers' expectations. We strive to create a seamless and
              satisfying experience from start to finish, ensuring every project
              is completed to the highest standards of quality and
              craftsmanship. Whether it's a small repair or a major renovation,
              we treat every job with the same level of care and attention to
              detail.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold border-b pb-1">What We Do</h2>
            <div>
              <h3 className="text-lg text-keppel-500 mb-1">Light Clean</h3>
              <p className="mb-4">
                Light cleaning services provide regular maintenance and upkeep
                for homes and offices, focusing on general tidying and minor
                cleaning tasks to maintain cleanliness and hygiene.
              </p>

              <h3 className="text-lg text-keppel-500 mb-1">
                Move-In/Out Cleaning
              </h3>
              <p className="mb-4">
                Move-In/Out cleaning services ensure a thorough clean of the
                property before new occupants move in or after old occupants
                move out, leaving the space spotless and ready for its new
                residents.
              </p>

              <h3 className="text-lg text-keppel-500 mb-1">
                Commercial Cleaning
              </h3>
              <p className="mb-4">
                Commercial cleaning services are designed for businesses and
                offices, providing professional cleaning to maintain a clean and
                productive work environment.
              </p>

              <h3 className="text-lg text-keppel-500 mb-1">
                Deep Cleaning Service
              </h3>
              <p className="mb-4">
                Deep cleaning services offer an intensive clean that goes beyond
                regular housekeeping, targeting hard-to-reach areas and thorough
                sanitization.
              </p>

              <h3 className="text-lg text-keppel-500 mb-1">
                Apartment Cleaning
              </h3>
              <p className="mb-4">
                Apartment cleaning services provide regular or one-time cleaning
                for apartment dwellers, ensuring a clean and comfortable living
                space.
              </p>

              <h3 className="text-lg text-keppel-500 mb-1">Pressure Washing</h3>
              <p className="mb-4">
                Pressure washing services use high-pressure water to clean
                various exterior surfaces, including houses, roofs, driveways,
                sidewalks, fans, and gutters.
              </p>

              <h3 className="text-lg text-keppel-500 mb-1">Handyman Job</h3>
              <p className="mb-4">
                Handyman services offer a wide range of home repair and
                maintenance tasks, providing skilled labor for various projects
                and fixes around the house.
              </p>
            </div>
          </div>

          <div>
            <h2 className="text-xl font-semibold border-b pb-1">Our Team</h2>
            <p>
              Our team of professionals is our greatest asset. Each member of
              SSA Home Services is highly trained, experienced, and dedicated to
              providing outstanding customer service. We believe in continuous
              learning and improvement, staying updated with the latest industry
              trends and techniques to ensure we deliver the best possible
              results.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold border-b pb-1">
              Why Choose Us?
            </h2>

            <h2>Get in Touch</h2>
            <p>
              We look forward to the opportunity to serve you. Contact SSA Home
              Services today to discuss your home improvement needs and schedule
              a consultation. Let us help you create the home of your dreams!
            </p>

            <div className="mt-3">
              <Button link={"/contact"}>Get in Touch</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
