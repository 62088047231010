import React from "react";
import Button from "../../shared/ui/Button";
import { FaArrowRight } from "react-icons/fa";

const ServiceCard = ({ service }) => {
  const image = require(`../../assets/images/${service.image}`);
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="rounded-md overflow-hidden flex flex-col gap-y-2  border-keppel-400 shadow-md m-3">
        <img src={image} alt={service.name} className="w-full h-60" />
        <div className="flex flex-col gap-y-2 p-5">
          <h1 className="text-keppel-500 font-bold text-2xl">{service.name}</h1>
          <p className="text-gray-500 text-sm line-clamp-2">
            {service.description}
          </p>
          <Button
            onClick={handleClick}
            secondary
            link={"/services/" + service.id}
            className={"block ms-auto mt-5"}
          >
            Learn More
            <FaArrowRight />
          </Button>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
